import React from "react"
import styles from "../../styles/index.module.scss"
import RWindow  from "../rwindow"

import { Socials } from "../socials"

const HomeWindow = () => {
  return (

    /*** Introduction Block */
    <RWindow name="home" title="Hey, I'm Kristen!" parentClass={styles.introWindow}>
    I'm currently a software engineer at Apple, working on the Siri Client team. I
    recently graduated from the University of British Columbia, where I
    studied Computer Science.
    <br /> <br />
    Find me on
    <Socials />
    </RWindow>
    
  )
}


export default HomeWindow