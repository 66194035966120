import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import styles from "../../styles/blog.module.scss"
import RWindow  from "../rwindow"
import {connect} from "react-redux"
import { BlogPreview } from "../blog-preview"
import { Helmet } from "react-helmet"


function mapStateToProps(state, ownProps) {
  return {
    props: ownProps
  }
}

const BlogWindow = ({props, dispatch}) => {

  const handleLinkClick = () => {
    // dispatch(toggleReload(true));
  }

  return (

    <div>
      <Helmet>

        <title>Blog | Kristen Kwong ★ Software Engineer</title>
        <meta itemprop="description" name="description" content="Blog posts written by Kristen Kwong." />

        {/* OPEN GRAPH TAGS */}
        <meta property="og:title" content="Blog | Kristen Kwong ★ Software Engineer"/>
        <meta property="og:url" content={"https://kristen.dev/blog"}/>
        <meta property="og:description" content="Blog posts written by Kristen Kwong."/>

        {/* <!--- TWITTER CARD ----> */}
        <meta name="twitter:title" content="Blog | Kristen Kwong ★ Software Engineer" />
        <meta name="twitter:description" content="Blog posts written by Kristen Kwong."/>
        </Helmet>
    
    <StaticQuery 
    query={graphql`
    query IndexQuery {

      allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            frontmatter {
              title
              tags
              date(formatString: "MMMM DD, YYYY")
              summary
            }
            fields {
              slug
            }
          }
        }
      }
    }`}

    render={data => (

    <RWindow name="blog" title="Some Thoughts" parentClass={styles.postWindow}>
  
    {data.allMdx.edges.map(({ node }, id) => (
      <BlogPreview
        key={id}
        path={node.fields.slug}
        onClick={handleLinkClick}
        date={node.frontmatter.date}
        title={node.frontmatter.title}
        summary={node.frontmatter.summary}
        tags={node.frontmatter.tags}/>
      // <li key={id}>
      //   <Link to={node.fields.slug} onClick={handleLinkClick}>
      //     <h2>{node.frontmatter.title}</h2>
      //   </Link>
      //   <p>{node.frontmatter.summary}</p>
      // </li>
    ))}
  </RWindow>

  )} /> 
  </div>)
}

export default connect(mapStateToProps, null)(BlogWindow);