import React, {useState} from "react"
import styles from "../styles/project.module.scss"

import {Button} from "./button"

export const Project = props => {

  const [state, setState] = useState({
    contentDisplay: "none"
  })

  const toggleContent = () => {
    if (state.contentDisplay === "none")
      setState({contentDisplay: "block"})
    else 
      setState({contentDisplay: "none"})
  }

  return (
    <div className={styles.container}>
      <div className={styles.dates}>
        <span className={styles.startDate}>{props.date}</span>
      </div>
      <div onClick={toggleContent} className={styles.expandButton} onKeyDown={toggleContent}>{state.contentDisplay === "none" ? "[+]" : "[-]"} <span className={styles.title}>{props.title}</span></div>
      <div className={styles.stack}>{props.stack}</div>
      <div className={styles.content} style={{display: state.contentDisplay}}>
        {props.children.split("\n").map((i, key) => {
            return <span key={key}>{i}<br /></span>
        }) }
        <div className={styles.imageContainer}> 
            {props.image ? <a href={props.image}><img className={styles.image} src={props.image} /></a> : ""}
        </div>
        <div className={styles.buttonsContainer}>
          {props.github ? <Button type="github" text="Github" href={props.github}/> : ""}
          {props.link ? <Button type="link" text="Link" href={props.link}/> : ""}
        </div>
    </div>
    
    
    </div>
  )
}