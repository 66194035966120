import React from "react"
import styles from "../../styles/index.module.scss"
import RWindow  from "../rwindow"
import { Helmet } from "react-helmet"

const ErrorWindow = () => {
  return (

    <div>
      <Helmet>

        <title>404 | Kristen Kwong ★ Software Engineer</title>
        <meta itemprop="description" name="description" content="This page doesn't exist!" />

        {/* OPEN GRAPH TAGS */}
        <meta property="og:title" content="404 | Kristen Kwong ★ Software Engineer"/>
        <meta property="og:url" content={"https://kristen.dev/404"}/>
        <meta property="og:description" content="This page doesn't exist!"/>

        {/* <!--- TWITTER CARD ----> */}
        <meta name="twitter:title" content="404 | Kristen Kwong ★ Software Engineer" />
        <meta name="twitter:description" content="This page doesn't exist!"/>
        </Helmet>

    {/*** Error Block */}
    <RWindow name="error" title="404: Page Not Found" parentClass={styles.introWindow}>
    Oops, this page doesn't exist! Click <a href="/">here</a> to go back
    to safety.
    </RWindow>

    </div>

  )
}

export default ErrorWindow