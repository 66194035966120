import React from "react"
import styles from "../styles/blog-summary.module.scss"
import { Helmet } from "react-helmet"

export const BlogPreview = props => {

  return (
    <div>
      <Helmet>

        <title>Blog | Kristen Kwong ★ Software Engineer</title>
        <meta itemprop="description" name="description" content="Blog posts written by Kristen Kwong." />

        {/* OPEN GRAPH TAGS */}
        <meta property="og:title" content="Blog | Kristen Kwong ★ Software Engineer"/>
        <meta property="og:url" content={"https://kristen.dev/blog"}/>
        <meta property="og:description" content="Blog posts written by Kristen Kwong."/>

        {/* <!--- TWITTER CARD ----> */}
        <meta name="twitter:title" content="Blog | Kristen Kwong ★ Software Engineer" />
        <meta name="twitter:description" content="Blog posts written by Kristen Kwong."/>
      </Helmet>
    
    <div className={styles.container}>
        <div className={styles.date}>{props.date}</div>
        <a href={props.path} className={styles.link}>{props.title}</a>
        <div className={styles.summary}>{props.summary}</div>
        <div className={styles.tags}>
          {props.tags.join(", ")} 
        </div>
    </div>
    </div>
  )
}