import React from "react"
import styles from "../../styles/index.module.scss"
import RWindow  from "../rwindow"
import projects from "../../data/projects.json"
import { Project } from "../project"
import { Helmet } from "react-helmet"

const ProjectWindow = () => {
  return (

    <div>
      <Helmet>

        <title>Projects | Kristen Kwong ★ Software Engineer</title>
        <meta itemprop="description" name="description" content="Projects that Kristen has worked on over the years." />

        {/* OPEN GRAPH TAGS */}
        <meta property="og:title" content="Projects | Kristen Kwong ★ Software Engineer"/>
        <meta property="og:url" content={"https://kristen.dev/projects"}/>
        <meta property="og:description" content="Projects that Kristen has worked on over the years."/>

        {/* <!--- TWITTER CARD ----> */}
        <meta name="twitter:title" content="Projects | Kristen Kwong ★ Software Engineer" />
        <meta name="twitter:description" content="Projects that Kristen has worked on over the years."/>
        </Helmet>

    {/*** Projects Block */}
    <RWindow name="projects" title="Projects" parentClass={styles.projectWindow}>
    <div className={styles.projectSummary}>
        Some of the various projects I've worked on, including academic projects. Due to academic policies or non-disclosures, some of the code isn't posted.
        {projects.map(project => (
            <Project 
                key={project.title} 
                title={project.title}
                date={project.date}
                stack={project.stack}
                github={project.github}
                link={project.link}
                image={project.image}>
                {project.content}
            </Project> 
        ))}
    </div>
    </RWindow>

    </div>

    
  )
}


export default ProjectWindow