import React, {useEffect} from "react"
import { Helmet } from "react-helmet"
import NavBar  from "./navbar"
import Footer  from "./footer"
import {connect} from "react-redux"
import {toggleHome, toggleExperience, toggleProjects, toggleError, toggleBlog} from "../state/app"

import BlogWindow from "./windows/blog"
import ProjectWindow from "./windows/projects"
import HomeWindow from "./windows/home"
import ExperienceWindow from "./windows/experience"
import ErrorWindow from "./windows/error"

function mapStateToProps(state, ownProps) {
  return {
    isHomeSelected: state.app.isHomeSelected,
    isExperienceSelected: state.app.isExperienceSelected,
    isProjectsSelected: state.app.isProjectsSelected,
    isErrorSelected: state.app.isErrorSelected,
    isBlogSelected: state.app.isBlogSelected,
    props: ownProps
  }
}

const Router = ({isHomeSelected, isExperienceSelected, isProjectsSelected, isErrorSelected, isBlogSelected, props, dispatch}) => {


  // componentDidMount
  useEffect(() => {

    switch (props.selected) {

    case "home":
      dispatch(toggleHome(true))
      return;
    case "experience":
      dispatch(toggleExperience(true))
      return;
    case "projects":
      dispatch(toggleProjects(true))
      return;
    case "error":
      dispatch(toggleError(true))
      return;
    case "blog":
      dispatch(toggleBlog(true))
      return;
    default:
      return;

    }
      
  }, [props.selected, dispatch])

  return (<div>
    <Helmet>

      <meta charSet="utf-8" />


      <title>Kristen Kwong ★ Software Engineer</title>
      <link rel="shortcut icon" type="image/png" href="/assets/images/icon.png" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="author" content="Kristen Kwong"/>
      <meta charset="utf-8"/>

      <meta itemprop="description" name="description" content="Kristen is a software engineer with a passion for development, design, and travel." />
      <meta name="description" content="Kristen is a software engineer with a passion for development, design, and travel."></meta>

      {/* OPEN GRAPH TAGS */}
      <meta property="og:locale" content="en_US"/>
      <meta property="og:type" content="article"/>
      <meta property="og:title" content="Kristen Kwong ★ Software Engineer"/>
      <meta property="og:url" content="https://kristen.dev"/>
      <meta property="og:image" content="/icon.png" />
      <meta property="og:site_name" content="Kristen Kwong ★ Software Engineer"/>
      <meta property="article:author" content="Kristen Kwong" />
      {/* <meta property="article:published_time" content="{{ page.date }}" /> */}
      <meta property="og:description" content="Kristen is a software engineer with a passion for development, design, and travel."/>

      {/* <!--- TWITTER CARD ----> */}
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content="Kristen Kwong ★ Software Engineer" />
      <meta name="twitter:description" content="Kristen is a software engineer with a passion for development, design, and travel." />
      <meta name="twitter:creator" content="@kristenkwng" />
    </Helmet>
    <NavBar />
    <div className="safe-area">
      <div className="safe-area-container">

      {isErrorSelected ? <ErrorWindow /> : ""}

      {isHomeSelected ? <HomeWindow /> : ""}

      {isExperienceSelected ? <ExperienceWindow /> : ""}

      {isProjectsSelected ?  <ProjectWindow /> : ""}

      {isBlogSelected ?  <BlogWindow /> : ""}

    </div>
    <Footer />
  </div>
  </div>)
}

export default connect(mapStateToProps, null)(Router);