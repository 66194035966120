import React from "react"
import styles from "../../styles/index.module.scss"
import RWindow  from "../rwindow"

import experiences from "../../data/experiences.json"
import { Experience } from "../experience"
import { Helmet } from "react-helmet"

const ExperienceWindow = () => {
  return (

    <div>
      <Helmet>

        <title>Experience | Kristen Kwong ★ Software Engineer</title>
        <meta itemprop="description" name="description" content="Kristen's work experience." />

        {/* OPEN GRAPH TAGS */}
        <meta property="og:title" content="Experience | Kristen Kwong ★ Software Engineer"/>
        <meta property="og:url" content={"https://kristen.dev/experience"}/>
        <meta property="og:description" content="Kristen's work experience."/>

        {/* <!--- TWITTER CARD ----> */}
        <meta name="twitter:title" content="Experience | Kristen Kwong ★ Software Engineer" />
        <meta name="twitter:description" content="Kristen's work experience."/>
        </Helmet>

    {/* /*** Experience Block */}
    <RWindow name="experience" title="Experience" parentClass={styles.experienceWindow}>
    <div className={styles.experienceSummary}>
        Here are some places I've worked at over the years:
        {experiences.map(experience => (
            <Experience 
                key={experience.title + experience.company} 
                dates={experience.date}
                company={experience.company}
                team={experience.team}
                title={experience.title}
                date={experience.date}>
                {experience.content}
            </Experience> 
        ))}
    </div>
    </RWindow>

    </div>

    
  )
}


export default ExperienceWindow