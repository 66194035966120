import React, {useState} from "react"
import styles from "../styles/experience.module.scss"

export const Experience = props => {

  const [state, setState] = useState({
    contentDisplay: "none"
  })

  const toggleContent = () => {
    if (state.contentDisplay === "none")
      setState({contentDisplay: "block"})
    else 
      setState({contentDisplay: "none"})
  }

  return (
    <div className={styles.container}>
      <div className={styles.dates}>
        <span className={styles.startDate}>{props.date}</span>
      </div>
      <a onClick={toggleContent}>{state.contentDisplay === "none" ? "[+]" : "[-]"} <span className={styles.company}>{props.company}</span></a>
      <div className={styles.team}>{props.team}</div>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.content} style={{display: state.contentDisplay}}>
  {props.children}</div>
    </div>
  )
}